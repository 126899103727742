import React, { ReactNode, useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";

import "./b2x-telia-label.scss";

type Props = {
  children: ReactNode;
  htmlFor: string;
  dataTestId?: string;
  className?: string;
};
export const TeliaLabel = ({ children, htmlFor, dataTestId, className }: Props) => {
  useEffect(() => {
    obsoleteWarning("b2x-telia-label", "components-label--docs");
  }, []);

  const classname = `b2x-telia-label ${className || ""}`.trim();
  return (
    <label className={classname} htmlFor={htmlFor} data-testid={dataTestId}>
      {children}
    </label>
  );
};
